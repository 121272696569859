import { ListUl } from "@styled-icons/boxicons-regular";
import {
    Envelope,
    Edit,
    UserPlus,
    UserX,
    Group,
    InfoCircle,
} from "@styled-icons/boxicons-solid";
import { observer } from "mobx-react-lite";
import { Link, useHistory } from "react-router-dom";
import { Profile, RelationshipStatus } from "revolt-api/types/Users";
import { UserPermission } from "revolt.js/dist/api/permissions";
import { Route } from "revolt.js/dist/api/routes";

import styles from "./UserProfile.module.scss";
import { Localizer, Text } from "preact-i18n";
import { useContext, useEffect, useLayoutEffect, useState } from "preact/hooks";

import { noop } from "../../../lib/js";

import ChannelIcon from "../../../components/common/ChannelIcon";
import ServerIcon from "../../../components/common/ServerIcon";
import Tooltip from "../../../components/common/Tooltip";
import UserBadges from "../../../components/common/user/UserBadges";
import UserIcon from "../../../components/common/user/UserIcon";
import { Username } from "../../../components/common/user/UserShort";
import UserStatus from "../../../components/common/user/UserStatus";
import Button from "../../../components/ui/Button";
import IconButton from "../../../components/ui/IconButton";
import Modal from "../../../components/ui/Modal";
import Overline from "../../../components/ui/Overline";
import Preloader from "../../../components/ui/Preloader";


import Markdown from "../../../components/markdown/Markdown";
import {
    ClientStatus,
    StatusContext,
    useClient,
} from "../../revoltjs/RevoltClient";
import { useIntermediate } from "../Intermediate";
import styled from "styled-components";
import { FONTS, MONOSPACE_FONTS } from "../../Theme";
import * as AiIcons from "react-icons/ai";
import { attachContextMenu } from 'preact-context-menu';
import { stopPropagation } from "../../../lib/stopPropagation";
import { Channel } from "revolt.js/dist/maps/Channels";
import WindowWrapper from "../../../pages/channels/windowWrapper/WindowWrapper";
import PMChat from "../../../pages/friends/PMChat";

interface Props {
    user_id: string;
    dummy?: boolean;
    onClose?: () => void;
    dummyProfile?: Profile;
}

MONOSPACE_FONTS["Space Mono"].load();
FONTS.Inter.load();

const UserAvatar = styled.div`
align-items: center;
justify-content: center;
flex-shrink: 0;

opacity: 0;

animation-name: fadeIn; 	
animation-delay: 0.15s;
animation-duration: 0.25s;      
animation-iteration-count: 1;
animation-fill-mode:forwards;



@keyframes fadeIn { 
	0% {opacity: 0;} // CSS properties at start
	100% {opacity: 1;} // CSS properties at end
}     `


const NFTWrapper = styled.h1`
    font-family: ${MONOSPACE_FONTS["Space Mono"].name};
    font-weight: 600;
    color: white;
`

const UserInfo = styled.p`
    font-family: ${MONOSPACE_FONTS["Space Mono"].name};
    font-weight: 400;
    color: white;
`

export const UserProfile = observer(
    ({ user_id, onClose, dummy, dummyProfile }: Props) => {
        const { openScreen, writeClipboard } = useIntermediate();

        const [profile, setProfile] = useState<undefined | null | Profile>(
            undefined,
        );
        const [mutual, setMutual] = useState<
            undefined | null | Route<"GET", "/users/id/mutual">["response"]
        >(undefined);
        const [isPublicBot, setIsPublicBot] = useState<
            undefined | null | boolean
        >();

        const history = useHistory();
        const client = useClient();
        const status = useContext(StatusContext);
        const [tab, setTab] = useState("profile");

        const user = client?.users?.get(user_id);
        const wallet = localStorage.getItem("wallet");

        if (!user) {
            if (onClose) useEffect(onClose, []);
            return null;
        }

        const users = mutual?.users.map((id) => client.users.get(id));

        const mutualGroups = [...client.channels.values()].filter(
            (channel) =>
                channel?.channel_type === "Group" &&
                channel.recipient_ids!.includes(user_id),
        );

        const mutualServers = mutual?.servers.map((id) =>
            client.servers.get(id),
        );

        useLayoutEffect(() => {
            if (!user_id) return;
            if (typeof profile !== "undefined") setProfile(undefined);
            if (typeof mutual !== "undefined") setMutual(undefined);
            if (typeof isPublicBot !== "undefined") setIsPublicBot(undefined);
            // eslint-disable-next-line
        }, [user_id]);

        useEffect(() => {
            if (dummy) {
                setProfile(dummyProfile);
            }
        }, [dummy, dummyProfile]);

        useEffect(() => {
            if (dummy) return;
            if (
                status === ClientStatus.ONLINE &&
                typeof mutual === "undefined"
            ) {
                setMutual(null);
                user.fetchMutual().then(setMutual);
            }
        }, [mutual, status, dummy, user]);

        useEffect(() => {
            if (dummy) return;
            if (
                status === ClientStatus.ONLINE &&
                typeof profile === "undefined"
            ) {
                setProfile(null);

                if (user.permission & UserPermission.ViewProfile) {
                    user.fetchProfile().then(setProfile).catch(noop);
                }
            }
        }, [profile, status, dummy, user]);

        useEffect(() => {
            if (
                status === ClientStatus.ONLINE &&
                user.bot &&
                typeof isPublicBot === "undefined"
            ) {
                setIsPublicBot(null);
                client.bots
                    .fetchPublic(user._id)
                    .then(() => setIsPublicBot(true))
                    .catch(noop);
            }
        }, [isPublicBot, status, user, client.bots]);

        const backgroundURL =
            profile &&
            client.generateFileURL(profile.background, { width: 1000 }, true);

        const badges = user.badges ?? 0;
        const flags = user.flags ?? 0;

        const [openChats, setOpenChats] = useState<any[]>([]);

        const removeChat = (delete_id: number) => {
            setOpenChats(openChats.filter((item: any) => {
                if (item.id != delete_id) {
                    return item;
                }
                return;
            }));
        }

        const addChat = (channel_id: any, x: any) => {
            const checkId = (obj: any) => obj.channel == channel_id._id;

            if (!openChats.some(checkId)) {
                setOpenChats([...openChats, {
                    id: openChats.length,
                    chat: (
                        <WindowWrapper
                            channel={channel_id}
                            index={1}
                            addWindow={() => void 0}
                            removeWindow={() => removeChat(openChats.length)}
                            isOverlay={true}
                            numberOfWindows={0}
                            pmContext={x.relationship === RelationshipStatus.Friend ? 'friend' : 'not-friend'}
                        >
                            <>
                                <PMChat user={x} id={channel_id} />
                            </>
                        </WindowWrapper>
                    ),
                    channel: channel_id._id
                }])
            }

        }

        function handleButtonClick(channel_id: Channel, x: any) {
            const channel = channel_id;
            addChat(channel, x);
        }

        return (
            <Modal
                visible
                border={dummy}
                padding={false}
                onClose={onClose}
                dontModal={dummy}>
                <div className="w-full flex justify-center">
                    <div className="flex justify-center items-center fixed">
                        <UserAvatar>
                            <UserIcon
                                size={135}
                                target={user}
                                status
                                animate
                                hover={typeof user.avatar !== "undefined"}
                                onClick={() =>
                                    user.avatar &&
                                    openScreen({
                                        id: "image_viewer",
                                        attachment: user.avatar,
                                    })
                                }
                            />
                        </UserAvatar>
                    </div>
                </div>
                <div style={{ backgroundColor: 'transparent' }}>
                    {openChats.map((item: any) => {
                        return (
                            <div key={item.key}>
                                {item.chat}
                            </div>
                        );
                    })}
                    <div className="p-5 overflow-hidden mt-10" style={{ backgroundColor: '#0F1114' }}>
                        <div
                            className={styles.header}
                            data-force={profile?.background ? "light" : undefined}
                            style={{
                                paddingBottom: "1px",
                            }}>
                            <div className="w-full flex justify-between items-center mx-5">
                                <button type="button" className="bg-[#1D2126] p-1 rounded mb-2" onClick={onClose}>
                                    <AiIcons.AiOutlineClose size="1.25rem" />
                                </button>

                                {user.relationship === RelationshipStatus.Friend && (
                                    <div class="rounded-full bg-black font-medium text-xs px-3 py-2 mr-3" style={{ fontFamily: FONTS.Inter.name }}>
                                        Friend
                                    </div>
                                )}

                                {user.relationship === RelationshipStatus.None && (
                                    <div class="rounded-full bg-black font-medium text-xs px-3 py-2 mr-3" style={{ fontFamily: FONTS.Inter.name }}>
                                        Not Friends
                                    </div>
                                )}

                                {user.relationship === RelationshipStatus.User && (
                                    <div class="rounded-full bg-black font-medium text-xs px-3 py-2 mr-3" style={{ fontFamily: FONTS.Inter.name }}>
                                        You
                                    </div>
                                )}

                            </div>
                            <div className={styles.profile}>
                                <div className="flex flex-col w-full justify-end items-center">
                                    <div className={styles.details}>
                                        <UserInfo
                                            className={styles.username}>
                                            {user.username} #{user._id}
                                        </UserInfo>
                                        {user.status?.text && (
                                            <span className={styles.status}>
                                                <UserStatus user={user} tooltip />
                                            </span>
                                        )}
                                    </div>
                                    <div className="w-full flex justify-center text-[#485262] underline text-sm font-medium" style={{ fontFamily: MONOSPACE_FONTS["Space Mono"].name }}>
                                        <button onClick={() => writeClipboard(wallet!)}>
                                            {wallet}
                                        </button>
                                    </div>

                                </div>
                            </div>
                            <div className="mt-40 flex gap-x-3 m-3">

                                {user.relationship !== RelationshipStatus.User && (
                                    <>
                                        {user.relationship === RelationshipStatus.None && (
                                            <button className="bg-[#1F2022] hover:bg-[#0F2228] active:bg-[#0F3338] border border-transparent active:border-white rounded transition py-3 flex justify-center items-center w-full font-bold text-sm" onClick={() => useClient().user?.addFriend()} style={{ fontFamily: FONTS.Inter.name }}>
                                                Add Friend
                                            </button>
                                        )}
                                        <div
                                            className="flex items-center justify-start gap-x-3 w-full"
                                            onContextMenu={attachContextMenu("Menu", { user: user._id })}>
                                            <button onClick={(ev) => stopPropagation(ev, user.openDM().then((channel: any) => (handleButtonClick(channel, user))))} className="bg-[#1F2022] hover:bg-[#0F2228] active:bg-[#0F3338] border border-transparent active:border-white rounded transition py-3 flex justify-center items-center w-full font-bold text-sm" style={{ fontFamily: FONTS.Inter.name }}>
                                                Send Message
                                            </button>
                                        </div>

                                        <button className="bg-[#1F2022] hover:bg-[#0F2228] active:bg-[#0F3338] border border-transparent active:border-white rounded transition py-3 flex justify-center items-center w-full font-bold text-sm" style={{ fontFamily: FONTS.Inter.name }} onClick={() => alert("Verify button pressed")}>
                                            Verify
                                        </button>
                                    </>
                                )}



                            </div>
                            <div className="bg-black rounded h-60 w-full p-5 flex flex-col overflow-y-auto">
                                {user.relationship === RelationshipStatus.User ? (
                                    <NFTWrapper>My NFTs</NFTWrapper>
                                ) : <NFTWrapper>NFTs</NFTWrapper>
                                }

                                <p className="text-xs text-gray-500 hover:text-gray-400 mt-2 mb-1">Public</p>
                                <div class="grid grid-cols-7 gap-2">
                                    <button type="button" className="w-24 h-24 object-fit overflow-hidden rounded border border-2 border-white hover:border-gray-300 transition">
                                        <img src="https://picsum.photos/200/300" alt="" className="h-auto w-auto object-fit" />
                                    </button>
                                    <button type="button" className="w-24 h-24 object-fit overflow-hidden rounded border border-2 border-white hover:border-gray-300 transition">
                                        <img src="https://picsum.photos/200/300" alt="" className="h-auto w-auto object-fit" />
                                    </button>
                                    <button type="button" className="w-24 h-24 object-fit overflow-hidden rounded border border-2 border-white hover:border-gray-300 transition">
                                        <img src="https://picsum.photos/200/300" alt="" className="h-auto w-auto object-fit" />
                                    </button>
                                </div>

                                {user.relationship === RelationshipStatus.User && (
                                    <>
                                        <p className="text-xs text-gray-500 hover:text-gray-400 mt-2 mb-1">Private</p>
                                        <div class="grid grid-cols-7 gap-2">
                                            <button type="button" className="w-24 h-24 object-fit overflow-hidden rounded">
                                                <img src="https://picsum.photos/200/300" alt="" className="h-auto w-auto object-fit" />
                                            </button>
                                            <button type="button" className="w-24 h-24 object-fit overflow-hidden rounded">
                                                <img src="https://picsum.photos/200/300" alt="" className="h-auto w-auto object-fit" />
                                            </button>
                                            <button type="button" className="w-24 h-24 object-fit overflow-hidden rounded">
                                                <img src="https://picsum.photos/200/300" alt="" className="h-auto w-auto object-fit" />
                                            </button>
                                            <button type="button" className="w-24 h-24 object-fit overflow-hidden rounded">
                                                <img src="https://picsum.photos/200/300" alt="" className="h-auto w-auto object-fit" />
                                            </button>
                                            <button type="button" className="w-24 h-24 object-fit overflow-hidden rounded">
                                                <img src="https://picsum.photos/200/300" alt="" className="h-auto w-auto object-fit" />
                                            </button>
                                            <button type="button" className="w-24 h-24 object-fit overflow-hidden rounded">
                                                <img src="https://picsum.photos/200/300" alt="" className="h-auto w-auto object-fit" />
                                            </button>
                                            <button type="button" className="w-24 h-24 object-fit overflow-hidden rounded">
                                                <img src="https://picsum.photos/200/300" alt="" className="h-auto w-auto object-fit" />
                                            </button>
                                            <button type="button" className="w-24 h-24 object-fit overflow-hidden rounded">
                                                <img src="https://picsum.photos/200/300" alt="" className="h-auto w-auto object-fit" />
                                            </button>
                                            <button type="button" className="w-24 h-24 object-fit overflow-hidden rounded">
                                                <img src="https://picsum.photos/200/300" alt="" className="h-auto w-auto object-fit" />
                                            </button>
                                        </div>
                                    </>
                                )}

                            </div>
                        </div>
                    </div>
                </div >

            </Modal >
        );
    },
);
