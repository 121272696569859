import * as RiIcons from "react-icons/ri";
import { Channel } from "revolt.js/dist/maps/Channels";
import styled from "styled-components";

import ChannelHeader from "../ChannelHeader";
import NewWindowModal from "../NewWindowModal";
import { observer } from "mobx-react-lite";
import { isTouchscreenDevice } from "../../../lib/isTouchscreenDevice";
import { Rnd } from "react-rnd";
import { FONTS } from "../../../context/Theme";
import { useEffect, useRef, useState } from "preact/hooks";
import { User } from "revolt.js/dist/maps/Users";
import { stopPropagation } from "../../../lib/stopPropagation";
import { useClient } from "../../../context/revoltjs/RevoltClient";
import { RelationshipStatus } from "revolt-api/types/Users";

FONTS.Inter.load();

type Props = {
    title?: string;
    channel?: Channel;
    children?: JSX.Element;
    index: number;
    pmContext?: string;
    numberOfWindows: number;
    isOverlay?: boolean;
    user?: any;
    removeWindow: Function;
    addWindow: Function;
};

const Frame = styled.div.attrs((props) => ({
    className: props.className,
}))`
    height: 100%;

    padding-bottom: 0.75rem;
    background-color: rgba(39, 41, 43, 0.03);
    flex: 1 0 0%;

    overflow: hidden;

    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-family: ${FONTS["Inter"].name}
`;

const Background = styled.div`
    background-color: #0f1114;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    align-items: center;
    min-height: 50px;
`;

const Title = styled.h3`
    text-transform: capitalize;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
`;

const DarkFrame = styled.div`
    max-height: 90vh;

    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
        0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
        0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
    border-radius: 0.5rem;
    width: 100%;
    height: 100%;
`;

const WindowButton = styled.button.attrs((props) => ({
    className: props.className,
}))`
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    font-weight: 500;
    border-color: transparent;
    border-width: 1px;
    border-radius: 0.375rem;
    align-items: center;
    position: relative;
    cursor: pointer;
    border-radius: 5px;
    padding: 4px;
    font-size: 18px;
    background-color: #1d2126;
    transition: all 0.3s linear;

    .mirrored {
        rotatey: 180deg;
    }

    svg {
        color: #c8c8c8;
    }

    svg.left {
        transform: rotateY(0);
    }

    svg.right {
        transform: rotateY(3.142rad);
    }

    svg:hover {
        color: white;
    }
`;

const Wrapper = styled.div`
    height: 85vh;
    margin-bottom: 10px;
`

export default observer(({
    title,
    channel,
    children,
    index,
    numberOfWindows,
    removeWindow,
    addWindow,
    isOverlay,
    user,
    pmContext
}: Props) => {
    const client = useClient();

    const handleCloseClick = (e: MouseEvent) => {
        e.stopPropagation();

        if (!e.shiftKey) {
            removeWindow(index);
        }
    };

    function handleAddChat() {
        addWindow("chat");
    }

    function handleAddBrowser() {
        addWindow("browser");
    }

    function handleAddVideo() {
        addWindow("video");
    }

    function handleAddDM(channel: Channel, user: User) {
        (ev: any) => stopPropagation(ev, user.openDM().then((channel: any) => (addWindow("DM", channel, user))));
        addWindow("DM", channel, user);
    }

    const [focus, setFocus] = useState(false);

    function useOutsideAlerter(ref: any) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event: any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setFocus(false);
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return (
        <Frame style={
            pmContext
                ? (
                    pmContext === 'friend'
                        ? (!isTouchscreenDevice ?
                            {
                                paddingTop: '3rem',
                                marginLeft: '3rem',
                                pointerEvent: 'none'
                            }
                            : {
                                paddingBottom: '3rem',
                                marginLeft: '3rem',
                                pointerEvent: 'none'

                            }
                        )
                        : { paddingBottom: '3.5rem' }
                )
                : { paddingTop: '0.75rem' }
        }>
            {isOverlay
                ?
                (
                    <div className="absolute left-0" style={{ zIndex: focus ? 10002 : 10001, bottom: 75 }}>
                        <Wrapper style={{ width: 'fit-content' }} >
                            <Rnd
                                default={{
                                    x: 500,
                                    y: -50,
                                    width: 800,
                                    height: 800
                                }}
                                minWidth={300}
                                minHeight={300}
                                bounds="window"
                                dragHandleClassName="handle"
                                className="border border-zinc-900 rounded-t-lg"
                                onClick={() => setFocus(true)}
                            >
                                <DarkFrame style={{ paddingBottom: 50, zIndex: 10000 }} ref={wrapperRef}>
                                    <Background>
                                        <div className="flex w-full items-center justify-between flex-wrap sm:flex-nowrap handle">
                                            <div className="my-3">
                                                <Title>
                                                    {channel ? (
                                                        <ChannelHeader channel={channel} />
                                                    ) : (
                                                        title
                                                    )}
                                                </Title>
                                            </div>

                                            <div className="flex flex-row gap-2 mx-3">
                                                {numberOfWindows % 2 != 0 && (
                                                    <NewWindowModal
                                                        addChat={handleAddChat}
                                                        addBrowser={handleAddBrowser}
                                                        addVideo={handleAddVideo}
                                                        addDM={handleAddDM}
                                                    />
                                                )}

                                                {
                                                    numberOfWindows % 2 == 0 &&
                                                    ((isOverlay)
                                                        ?
                                                        <WindowButton
                                                            type="button" onClick={() => removeWindow()}>
                                                            <RiIcons.RiCloseFill size="1.5em" />
                                                        </WindowButton>

                                                        :
                                                        <WindowButton
                                                            type="button"
                                                            onClick={handleCloseClick}>
                                                            <RiIcons.RiCloseFill size="1.5em" />
                                                        </WindowButton>
                                                    )
                                                }

                                            </div>
                                        </div>
                                    </Background>

                                    <div className="w-full absolute flex justify-content-around bg-black gap-x-4 p-2 pb-3 mr-3" style={{ zIndex: 10000 }}>
                                        <button className="bg-[#0F1114] hover:bg-[#0F2228] active:bg-[#0F3338] border border-transparent active:border-white rounded transition py-3 flex justify-center items-center w-full font-medium" style={{ zIndex: 10000 }}>
                                            Send ETH
                                        </button>
                                        <button className="bg-[#0F1114] hover:bg-[#0F2228] active:bg-[#0F3338] border border-transparent active:border-white rounded transition py-3 flex justify-center items-center w-full font-medium" style={{ zIndex: 10000 }}>
                                            Send PXN
                                        </button>
                                        {
                                            // If user is not friends with the client, then show this: 
                                            /*                                             !(client.user?.relationship === RelationshipStatus.Friend) &&
                                                                                        <button className="bg-[#0F1114] hover:bg-[#0F2228] active:bg-[#0F3338] border border-transparent active:border-white rounded transition py-3 flex justify-center items-center w-full font-medium"
                                                                                            onClick={user?.addFriend()}
                                                                                            style={{ zIndex: 10000 }}>
                                                                                            Add Friend
                                                                                        </button> */
                                        }
                                    </div>

                                    <div className="h-full" style={{ paddingTop: 70 }}>{children}</div>
                                </DarkFrame>
                            </Rnd>
                        </Wrapper>
                    </div>
                )
                :
                (<DarkFrame>
                    <Background>
                        <div className="flex w-full items-center justify-between flex-wrap sm:flex-nowrap">
                            <div className="my-3">
                                <Title>
                                    {channel ? (
                                        <ChannelHeader channel={channel} />
                                    ) : (
                                        title
                                    )}
                                </Title>
                            </div>

                            <div className="flex flex-row gap-2 mx-3">
                                {numberOfWindows % 2 != 0 && (
                                    <NewWindowModal
                                        addChat={handleAddChat}
                                        addBrowser={handleAddBrowser}
                                        addVideo={handleAddVideo}
                                        addDM={handleAddDM}
                                    />
                                )}

                                {numberOfWindows % 2 == 0 &&
                                    <WindowButton
                                        type="button"
                                        onClick={handleCloseClick}>
                                        <RiIcons.RiCloseFill size="1.5em" />
                                    </WindowButton>
                                }
                            </div>
                        </div>
                    </Background>

                    <div className="h-full">{children}</div>
                </DarkFrame>
                )
            }

        </Frame >
    );
})