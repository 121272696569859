import React, { useState } from 'react'
import { User } from 'revolt.js/dist/maps/Users';
import styled from 'styled-components';
import WindowContent from './../channels/windowContent/windowContent';
import { Channel } from 'revolt.js/dist/maps/Channels';

type Props = {
    children?: JSX.Element;
    user: User;
    id?: Channel;
}

const ContentWrapper = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

function PMChat({ children, user, id }: Props) {
    return (
        <ContentWrapper>
            {
                id != undefined
                    ? <WindowContent content="channel" channel={id} addWindow={() => { void 0 }} />
                    : ''
            }
        </ContentWrapper>
    )
}

export default PMChat