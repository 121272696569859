import React from "react";
import * as BsIcons from "react-icons/bs";
import * as FaIcons from "react-icons/fa";
import { Channel as ChannelI } from "revolt.js/dist/maps/Channels";
import styled from "styled-components";

import { FONTS } from "../../../../context/Theme";

import BrowserContent from "./BrowserContent";
import ChatContent from "./ChatContent";
import VideoContent from "./VideoContent";

const DefaultWarning = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
`;

const Text = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    row-gap: 5px;
    font-family: ${FONTS.Inter.name};
`;

const Title = styled.h1`
    font-size: 36px;
    font-weight: bold;
`;

const Subtitle = styled.p`
    font-size: 14px;
`;

const ButtonGroup = styled.div`
    display: flex;
    justify-content: center;
    gap: 10px;
`;

const Button = styled.button`
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    font-weight: 500;
    border-color: transparent;
    border-width: 1px;
    border-radius: 0.375rem;
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    border-radius: 5px;
    padding: 5px 20px;
    font-size: 18px;
    background-color: #1d2126;
    transition: all 0.3s linear;
    gap: 5px;

    svg {
        color: #c8c8c8;
        transition: all linear 0.2s;
    }

    svg:hover {
        color: white;
    }
`;

const interFont = FONTS.Inter;
interFont.load();

interface Functions {
    addWindow: Function;
}

export function DefaultContent({ addWindow }: Functions) {
    const handleAddChat = (e: MouseEvent) => {
        e.stopPropagation();

        if (!e.shiftKey) {
            addWindow("chat");
        }
    };

    const handleAddBrowser = (e: MouseEvent) => {
        e.stopPropagation();

        if (!e.shiftKey) {
            addWindow("browser");
        }
    };

    const handleAddVideo = (e: MouseEvent) => {
        e.stopPropagation();

        if (!e.shiftKey) {
            addWindow("video");
        }
    };

    return (
        <DefaultWarning>
            <FaIcons.FaRegLightbulb size="6em" />
            <Text>
                <Title>It feels empty here!</Title>
                <Subtitle>Start by adding something to this tab.</Subtitle>
            </Text>
            <ButtonGroup>
                <Button onClick={handleAddChat}>
                    <BsIcons.BsFillChatDotsFill size="1.75em" />
                    <Subtitle>Chat</Subtitle>
                </Button>
                <Button onClick={handleAddBrowser}>
                    <BsIcons.BsYoutube size="1.75em" />
                    <Subtitle>Video</Subtitle>
                </Button>
                <Button onClick={handleAddVideo}>
                    <BsIcons.BsGlobe size="1.75em" />
                    <Subtitle>Browser</Subtitle>
                </Button>
            </ButtonGroup>
        </DefaultWarning>
    );
}

type Props = {
    content: string;
    videoLink?: string;
    channel: ChannelI;
    website?: string;
    addWindow: Function;
};

export default class ReturnContent extends React.Component<Props> {
    typeContent = (contentName: string) => {
        const defaultContent = (
            <DefaultContent addWindow={this.props.addWindow} />
        );
        const map: { [key: string]: any } = {
            video: <VideoContent videoLink={this.props.videoLink} />,
            channel: <ChatContent channel={this.props.channel} />,
            browser: <BrowserContent website={this.props.website} />,
        };
        return map[contentName] || defaultContent;
    };

    render() {
        const contentName = this.props.content;
        return this.typeContent(contentName);
    }
}
