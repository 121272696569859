import styled from "styled-components";

const VideoWrapper = styled.div`
    position: flex;
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const YoutubeVideo = styled.iframe`
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
`;

type Props = {
    videoLink?: string;
};

function VideoContent({ videoLink }: Props) {
    return (
        <VideoWrapper>
            <YoutubeVideo
                width="1904"
                height="768"
                src={"https://www.youtube.com/embed/" + videoLink}
                frameBorder="0"
            />
        </VideoWrapper>
    );
}

export default VideoContent;
