import { reaction } from "mobx";
import { Channel as ChannelI } from "revolt.js/dist/maps/Channels";
import styled from "styled-components";

import { useEffect, useState } from "preact/hooks";

import ErrorBoundary from "../../../../lib/ErrorBoundary";
import { internalSubscribe } from "../../../../lib/eventEmitter";

import { FONTS } from "../../../../context/Theme";

import MessageBox from "../../../../components/common/messaging/MessageBox";
import JumpToBottom from "../../../../components/common/messaging/bars/JumpToBottom";
import NewMessages from "../../../../components/common/messaging/bars/NewMessages";
import TypingIndicator from "../../../../components/common/messaging/bars/TypingIndicator";

import VoiceHeader from "../../voice/VoiceHeader";
import { MessageArea } from "../../messaging/MessageArea";

type Props = {
    channel: ChannelI;
};

const ChannelMain = styled.div.attrs({ "data-component": "channel" })`
    flex-grow: 1;
    display: flex;
    min-height: 0;
    height: 100%;
    font-family: ${FONTS.Inter.name};
    overflow: hidden;
    background-color: #000;
    flex-direction: row;
`;

const ChannelContent = styled.div.attrs({
    "data-component": "content",
})`
    flex-grow: 1;
    display: flex;
    overflow: hidden;
    flex-direction: column;
`;

function ChatContent({ channel }: Props) {
    // Store unread location.
    const [lastId, setLastId] = useState<string | undefined>(undefined);

    useEffect(
        () =>
            internalSubscribe("NewMessages", "hide", () =>
                setLastId(undefined),
            ),
        [],
    );

    useEffect(
        () => internalSubscribe("NewMessages", "mark", setLastId as any),
        [],
    );

    // Mark channel as read.
    useEffect(() => {
        setLastId(
            channel.unread
                ? channel.client.unreads?.getUnread(channel._id)?.last_id
                : undefined ?? undefined,
        );

        const checkUnread = () =>
            channel.unread &&
            channel.client.unreads!.markRead(
                channel._id,
                channel.last_message_id!,
                true,
            );

        checkUnread();
        return reaction(
            () => channel.last_message_id,
            () => checkUnread(),
        );
    }, [channel]);

    return (
        <ChannelMain>
            <ErrorBoundary section="renderer">
                <ChannelContent>
                    <VoiceHeader id={channel._id} />
                    <NewMessages channel={channel} last_id={lastId} />
                    <MessageArea channel={channel} last_id={lastId} />
                    <TypingIndicator channel={channel} />
                    <JumpToBottom channel={channel} />
                    <MessageBox channel={channel} />
                </ChannelContent>
            </ErrorBoundary>
        </ChannelMain>
    );
}

export default ChatContent;
