import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import { lazy, Suspense } from "preact/compat";

import ErrorBoundary from "../lib/ErrorBoundary";
import FakeClient from "../lib/FakeClient";

import Context from "../context";
import { CheckAuth } from "../context/revoltjs/CheckAuth";

import Masks from "../components/ui/Masks";
import Preloader from "../components/ui/Preloader";

const SideBar = lazy(() => import("../components/SideBar"));
import Invite from "./invite/Invite";
import Discover from "./discover/Discover";

const InfoBoard = lazy(() => import("./infoBoard/InfoBoard"));
const Login = lazy(() => import("./login/Login"));
const RevoltApp = lazy(() => import("./RevoltApp"));
const LoginConsole = lazy(() => import("./loginConsole/Login"));
const Classified = lazy(() => import("./classified/Classified"));
const Home = lazy(() => import("./home/Home"));
const Sidebar = lazy(() => import('./../components/SideBar'));
const Settings = lazy(() => import("../pages/settings/Settings"));
const NotFound = lazy(() => import("./notFound/NotFound"));
const InfoboardAdmin = lazy(() => import("../pages/infoboard-admin/Infoboard-admin"));


export function App() {
    return (
        <ErrorBoundary section="client">
            <Context>
                <Masks />
                {/* 
                // @ts-expect-error typings mis-match between preact... and preact? */}
                <Suspense fallback={<Preloader type="spinner" />}>
                    <Switch>
                        <Route path="/login">
                            <LoginConsole />
                        </Route>
                        {/*
                        <Route path="/infoboard">
                            <CheckAuth>
                                <Sidebar />
                                <InfoBoard />
                            </CheckAuth>
                        </Route> */}
                        <Route path="/chats">
                            <CheckAuth auth>
                                <RevoltApp />
                            </CheckAuth>
                        </Route>
                        <Route path="/classified">
                            <CheckAuth>
                                <Sidebar />
                                <Classified />
                            </CheckAuth>
                        </Route>
                        <Route path="/infoboard-admin">
                            <Sidebar />
                            <InfoboardAdmin />
                        </Route>
                        <Route path="/chat">
                            <Sidebar />
                            <Home />
                        </Route>
                        {/*  
                        // Disabled
                        <Route path="/discover">
                            <CheckAuth auth>
                                <Sidebar />
                                <Discover />
                            </CheckAuth>
                        </Route> */}
                        <Route path="/settings">
                            <CheckAuth auth>
                                <SideBar />
                                <Settings />
                            </CheckAuth>
                        </Route>
                        <Route path="/login/verify/:token">
                            <Login />
                        </Route>
                        <Route path="/login/reset/:token">
                            <Login />
                        </Route>
                        <Route path="/invite/:code">
                            <CheckAuth blockRender>
                                <FakeClient>
                                    <Invite />
                                </FakeClient>
                            </CheckAuth>
                            <CheckAuth auth blockRender>
                                <Invite />
                            </CheckAuth>
                        </Route>
                        <Route path="/login-console">
                            <CheckAuth redirect>
                                <Login />
                            </CheckAuth>
                        </Route>
                        <Route exact path="/">
                            <CheckAuth>
                                <Sidebar />
                                <InfoBoard />
                            </CheckAuth>
                        </Route>
                        <Route>
                            <Redirect to="/" />
                            {/* 
                        // 404 page
                            <CheckAuth>
                                <NotFound />
                            </CheckAuth>
                            */}
                        </Route>

                    </Switch>
                </Suspense>
            </Context>
        </ErrorBoundary>
    );
}
