import { Channel } from "revolt.js/dist/maps/Channels";
import { User } from "revolt.js/dist/maps/Users";
import styled from "styled-components";

import ReturnContent from "./content/ReturnContent";

const ContentWrapper = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

type Props = {
    content: string;
    videoLink?: string;
    channel: Channel;
    website?: string;
    addWindow: Function;
};

/*
    Types of accepted content:
        - video     (Youtube videos)
        - chatId    (Chat with some user)
        - { empty } (If no content is specified, it will show a default suggestion to the user to start using this tab.)
*/

export default function WindowContent({
    content = "",
    videoLink,
    channel,
    website,
    addWindow,
}: Props) {
    return (
        <ContentWrapper>
            <ReturnContent
                content={content}
                videoLink={videoLink}
                channel={channel}
                website={website}
                addWindow={addWindow}
            />
        </ContentWrapper>
    );
}
