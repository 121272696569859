import { Dialog, Transition } from "@headlessui/react";
import { useEffect, useRef } from "preact/hooks";
import { useState } from "react";
import * as BsIcons from "react-icons/bs";
import * as RiIcons from "react-icons/ri";
import styled from "styled-components";
import { attachContextMenu } from 'preact-context-menu';
import { isTouchscreenDevice } from "../../lib/isTouchscreenDevice";
import { stopPropagation } from "../../lib/stopPropagation";
import { RelationshipStatus, Presence } from "revolt-api/types/Users";
import UserIcon from "../../components/common/user/UserIcon";
import { useClient } from "../../context/revoltjs/RevoltClient";
import { Children } from "../../types/Preact";
import { User } from "revolt.js/dist/maps/Users";
import { Channel } from "revolt.js/dist/maps/Channels";
import { FONTS } from "../../context/Theme";

FONTS.Inter.load();

const Wrapper = styled.div`
    height: 100%;
    width: auto;
    display: flex;
    align-items: center;
`;

const Username = styled.h1`
    font-size: 14px;
    font-weight: 600;
    color: #FFFFFF;
    cursor: pointer;
    font-family: ${FONTS.Inter.name}
`;


const WindowButton = styled.button.attrs((props) => ({
    className: props.className,
}))`
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    font-weight: 500;
    border-color: transparent;
    border-width: 1px;
    border-radius: 0.375rem;
    align-items: center;
    position: relative;
    cursor: pointer;
    border-radius: 5px;
    padding: 4px;
    font-size: 18px;
    background-color: #1d2126;
    transition: all 0.3s linear;

    .mirrored {
        rotatey: 180deg;
    }

    svg {
        color: #c8c8c8;
    }

    svg.left {
        transform: rotateY(0);
    }

    svg.right {
        transform: rotateY(3.142rad);
    }

    svg:hover {
        color: white;
    }
`;

const ButtonGroup = styled.div`
    display: flex;
    justify-content: center;
    gap: 10px;
`;

const Button = styled.button`
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    font-weight: 500;
    border-color: transparent;
    border-width: 1px;
    border-radius: 0.375rem;
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    border-radius: 5px;
    padding: 10px 25px;
    font-size: 18px;
    background-color: #1d2126;
    transition: all 0.3s linear;
    gap: 5px;

    svg {
        color: #c8c8c8;
        transition: all linear 0.2s;
    }

    svg:hover {
        color: white;
    }
`;

const Title = styled.h1`
    font-size: 28px;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 20px;
`;

const Subtitle = styled.p`
    font-size: 16px;
`;

interface Functions {
    addChat: Function;
    addBrowser: Function;
    addVideo: Function;
    addDM: Function
}

export default function NewWindowModal({
    addChat,
    addBrowser,
    addVideo,
    addDM
}: Functions) {
    const [open, setOpen] = useState(false);
    const [chatSelected, setChatSelected] = useState(false);

    const client = useClient();
    const users = [...client.users.values()];
    users.sort((a, b) => a.username.localeCompare(b.username));

    const friends = users.filter(
        (x) => x.relationship === RelationshipStatus.Friend,
    );
    const lists = [
        [
            "",
            users.filter((x) => x.relationship === RelationshipStatus.Incoming),
        ],
        [
            "app.special.friends.sent",
            users.filter((x) => x.relationship === RelationshipStatus.Outgoing),
            "outgoing",
        ],
        [
            "app.status.online",
            friends.filter(
                (x) => x.online && x.status?.presence !== Presence.Invisible,
            ),
            "online",
        ],
        [
            "app.status.offline",
            friends.filter(
                (x) => !x.online || x.status?.presence === Presence.Invisible,
            ),
            "offline",
        ],
        [
            "app.special.friends.blocked",
            users.filter((x) => x.relationship === RelationshipStatus.Blocked),
            "blocked",
        ],
    ] as [string, User[], string][];

    const incoming = lists[0][1];
    const userlist: Children[] = incoming.map((x) => (
        <b key={x._id}>{x.username}</b>
    ));
    for (let i = incoming.length - 1; i > 0; i--) userlist.splice(i, 0, ", ");

    const handleClick = () => {
        setOpen(!open);
    };

    const handleAddChat = (e: MouseEvent) => {
        e.stopPropagation();

        if (!e.shiftKey) {
            addChat();
        }
    };

    const handleAddBrowser = (e: MouseEvent) => {
        e.stopPropagation();

        if (!e.shiftKey) {
            addBrowser();
        }
    };

    const handleAddVideo = (e: MouseEvent) => {
        e.stopPropagation();

        if (!e.shiftKey) {
            addVideo();
        }
    };

    function handleButtonClick(channel_id: Channel, x: any) {
        const channel = channel_id;
        addDM(channel, x);
    }

    function useOutsideAlerter(ref: any) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event: any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setOpen(false);
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const delay = 0.5;

    useEffect(() => {
        let timer1 = setTimeout(() => setChatSelected(false), delay * 1000);

        return () => {
            clearTimeout(timer1);
        };
    }, [open])

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return (
        <Wrapper>
            {!isTouchscreenDevice
                ?
                <WindowButton type="button" onClick={handleClick}>
                    <RiIcons.RiAddFill size="1.5em" />
                </WindowButton>
                : ''
            }
            <Transition.Root show={open}>
                <Dialog
                    as="div"
                    className="fixed z-10 inset-0 overflow-y-auto"
                    onClose={setOpen}>
                    <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center">
                        <Transition.Child
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0">
                            <Dialog.Overlay className="fixed inset-0 bg-[#000] bg-opacity-60 transition-opacity" />
                        </Transition.Child>

                        <div className="h-full flex items-center justify-center w-full" ref={wrapperRef}>
                            <Transition.Child
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                                <div className="inline-block align-bottom bg-black border border-zinc-900 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6 sm:min-w-[32rem]" style={{ minHeight: 300 }}>
                                    {chatSelected
                                        ?
                                        (<div className="flex flex-col justify-center items-center">
                                            <div className="w-full flex justify-end">
                                                <p className="text-left text-md text-medium w-full mt-3" style={{ fontFamily: FONTS.Inter.name }}>
                                                    Start a conversation with...
                                                </p>
                                                <button
                                                    type="button"
                                                    className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none  sm:text-sm"
                                                    onClick={() => setOpen(false)}>
                                                    <RiIcons.RiCloseFill size="1.75em" />
                                                </button>
                                            </div>
                                            <div className="h-full w-full flex flex-col justify-between items-center gap-y-1">
                                                <div className="flex flex-col w-full h-full">
                                                    {lists.map(([i18n, list, section_id], index) => {
                                                        if (index === 0) return;
                                                        if (list.length === 0) return;

                                                        return (
                                                            <>
                                                                {list.map((x) => (
                                                                    (section_id !== 'outgoing') &&
                                                                    <div
                                                                        className="flex items-center justify-start gap-x-3 w-full"
                                                                        onContextMenu={attachContextMenu("Menu", { user: x._id })}
                                                                        key={index}>
                                                                        <button onClick={(ev) => { stopPropagation(ev, x.openDM().then((channel) => (handleButtonClick(channel, x)))); setOpen(false); }} className="bg-transparent hover:bg-[#121212] active:bg-zinc-900 transition flex gap-x-3 p-3 items-center rounded w-full">
                                                                            <UserIcon target={x} size={36} status />
                                                                            <Username>{x.username}</Username>
                                                                        </button>
                                                                    </div>
                                                                ))}
                                                            </>
                                                        );
                                                    })}
                                                </div>

                                                <div className="flex absolute bottom-0 m-3 w-full justify-end" style={{ right: 10 }}>
                                                    <button className="bg-zinc-900 hover:bg-zinc-800 active:bg-zinc-900 px-3 py-2 rounded transition border border-transparent active:border-white text-sm" onClick={handleAddChat} style={{ fontFamily: FONTS.Inter.name }}>
                                                        Open current channel in a new window
                                                    </button>
                                                </div>

                                            </div>
                                        </div>)
                                        :
                                        (<div className="h-full">
                                            <div className="w-full flex justify-end">
                                                <button
                                                    type="button"
                                                    className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none sm:text-sm"
                                                    onClick={() => setOpen(false)}>
                                                    <RiIcons.RiCloseFill size="1.75em" />
                                                </button>
                                            </div>
                                            <div className="w-full h-full flex flex-col justify-center items-center">
                                                <div className="w-full flex justify-center items-center">
                                                    <Title>Open a new window</Title>
                                                </div>
                                                <div className="w-full flex justify-center">
                                                    <ButtonGroup>
                                                        <Button onClick={() => setChatSelected(true)}>
                                                            <BsIcons.BsFillChatDotsFill size="1.75em" />
                                                            <Subtitle>Chat</Subtitle>
                                                        </Button>
                                                        <Button onClick={handleAddBrowser}>
                                                            <BsIcons.BsGlobe size="1.75em" />
                                                            <Subtitle>Browser</Subtitle>
                                                        </Button>
                                                        <Button onClick={handleAddVideo}>
                                                            <BsIcons.BsYoutube size="1.75em" />
                                                            <Subtitle>Video</Subtitle>
                                                        </Button>
                                                    </ButtonGroup>
                                                </div>
                                            </div>
                                        </div>)
                                    }
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root >
        </Wrapper >
    );
}
