import { Redirect } from "react-router-dom";

import { useApplicationState } from "../../mobx/State";

import { Children } from "../../types/Preact";
import { useClient } from "./RevoltClient";

interface Props {
    auth?: boolean;
    blockRender?: boolean;
    redirect?: boolean;

    children: Children;
}

export const CheckAuth = (props: Props) => {
    const auth = localStorage.getItem("authToken") && localStorage.getItem("eulaAccepted");
    const client = useClient();
    const ready = auth && !!client?.user;

    if (props.auth && !ready) {
        if (props.blockRender) return null;
        return <Redirect to="/" />;
    } else if (!auth) {
        if (props.blockRender) return null;
        return <Redirect to="/login" />;
    } else if (props.redirect && ready) {
        if (props.blockRender) return null;
        return <Redirect to="/" />;
    }

    /*
        // Old code
        const auth = useApplicationState().auth;
        const client = useClient();
        const ready = auth.isLoggedIn() && !!client?.user;
     */

    /*   if (props.auth && !ready) {
          if (props.blockRender) return null;
          return <Redirect to="/login" />;
      } else if (!props.auth && ready) {
          if (props.blockRender) return null;
          return <Redirect to="/" />;
      } */

    return <>{props.children}</>;
};
