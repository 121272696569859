import styled from "styled-components";

type Props = {
    website?: string;
};

const BrowserWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;

    padding: 10px;
    display: flex;
    align-items: start;
    justify-content: center;
`;

const Browser = styled.iframe`
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 80vh;
`;

function BrowserContent({
    website = "https://www.google.com/webhp?igu=1",
}: Props) {
    return (
        <BrowserWrapper>
            <Browser src={website}></Browser>
        </BrowserWrapper>
    );
}

export default BrowserContent;
