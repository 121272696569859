import styled from "styled-components/macro";

import { RefObject } from "preact";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "preact/hooks";

import TextArea, { TextAreaProps } from "../components/ui/TextArea";

import { internalSubscribe } from "./eventEmitter";
import { isTouchscreenDevice } from "./isTouchscreenDevice";
import Emojis from "../components/common/messaging/Emojis";
import { emojiDictionary } from "../assets/emojis";
import { useApplicationState } from "../mobx/State";
import { stopPropagation } from './stopPropagation';
import Popovers from './../context/intermediate/Popovers';

type TextAreaAutoSizeProps = Omit<
    JSX.HTMLAttributes<HTMLTextAreaElement>,
    "style" | "value" | "onChange" | "children" | "as"
> &
    TextAreaProps & {
        forceFocus?: boolean;
        autoFocus?: boolean;
        minHeight?: number;
        maxRows?: number;
        value: string;
        setNewMessage?: any;
        id?: string;

        onChange?: (ev: JSX.TargetedEvent<HTMLTextAreaElement, Event>) => void;
    };

const Container = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;

const EmojiButton = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0.75rem;
`

const Ghost = styled.div<{ lineHeight: string; maxRows: number }>`
    flex: 0;
    width: 100%;
    overflow: hidden;
    visibility: hidden;
    position: relative;

    > div {
        width: 100%;
        white-space: pre-wrap;
        word-break: break-all;

        top: 0;
        position: absolute;
        font-size: var(--text-size);
        line-height: ${(props) => props.lineHeight};

        max-height: calc(
            calc(${(props) => props.lineHeight} * ${(props) => props.maxRows})
        );
    }
`;

const Emoji = styled.button`
    :hover {
        background-color: #1d2126;
        border-radius: 2px;
    }
`

export default function TextAreaAutoSize(props: TextAreaAutoSizeProps) {
    const {
        autoFocus,
        minHeight,
        maxRows,
        value,
        padding,
        lineHeight,
        hideBorder,
        forceFocus,
        onChange,
        ...textAreaProps
    } = props;

    const ref = useRef<HTMLTextAreaElement>() as RefObject<HTMLTextAreaElement>;
    const ghost = useRef<HTMLDivElement>() as RefObject<HTMLDivElement>;

    const [query, setQuery] = useState("");
    const [open, setOpen] = useState(false);

    useLayoutEffect(() => {
        if (ref.current && ghost.current) {
            ref.current.style.height = `${ghost.current.clientHeight}px`;
        }
    }, [ghost, props.value]);

    useEffect(() => {
        if (isTouchscreenDevice) return;
        autoFocus && ref.current && ref.current.focus();
    }, [value, autoFocus]);

    const inputSelected = () =>
        ["TEXTAREA", "INPUT"].includes(document.activeElement?.nodeName ?? "");

    useEffect(() => {
        if (!ref.current) return;
        if (forceFocus) {
            ref.current.focus();
        }

        if (isTouchscreenDevice) return;
        if (autoFocus && !inputSelected()) {
            ref.current.focus();
        }

        // ? if you are wondering what this is
        // ? it is a quick and dirty hack to fix
        // ? value not setting correctly
        // ? I have no clue what's going on
        // ref.current.value = value;
        // * commented out of 30-08-21
        // * hopefully nothing breaks :v

        if (!autoFocus) return;
        function keyDown(e: KeyboardEvent) {
            if ((e.ctrlKey && e.key !== "v") || e.altKey || e.metaKey) return;
            if (e.key.length !== 1) return;
            if (ref && !inputSelected()) {
                ref.current!.focus();
            }
        }

        document.body.addEventListener("keydown", keyDown);
        return () => document.body.removeEventListener("keydown", keyDown);
    }, [ref, autoFocus, forceFocus, value]);

    useEffect(() => {
        if (!ref.current) return;
        function focus(id: string) {
            if (id === props.id) {
                ref.current!.focus();
            }
        }

        return internalSubscribe(
            "TextArea",
            "focus",
            focus as (...args: unknown[]) => void,
        );
    }, [props.id, ref]);

    const [postobe, setPostobe] = useState<any>(undefined);

    useEffect(() => {
        if (postobe) {
            ref.current!.focus();
            ref.current!.setSelectionRange(postobe, postobe);
        }
    }, [postobe])

    function setEmojiOnTextArea(emoji: string) {
        let currentPos = ref.current?.selectionStart;
        let text = ref.current?.value;
        let output = [text?.slice(0, currentPos), emoji, text?.slice(currentPos)].join('');

        props.setNewMessage(output);

        if (currentPos) {
            setPostobe(currentPos + emoji.length);
        }
    }

    function useOutsideAlerter(ref: any) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event: any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setOpen(false);
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return (
        <Container>
            <div className="flex items-center">
                <TextArea
                    ref={ref}
                    value={value}
                    padding={padding}
                    style={{ minHeight }}
                    hideBorder={hideBorder}
                    lineHeight={lineHeight}
                    onChange={(ev) => {
                        onChange && onChange(ev);
                    }}
                    {...textAreaProps}
                />

                <EmojiButton<any> ref={wrapperRef}>
                    <Emojis open={open} setOpen={setOpen} setQuery={setQuery}>
                        {
                            <>
                                {Object.entries(emojiDictionary).filter(([key, val]) => {
                                    if (query === '') {
                                        return key;
                                    } else if (key.toLowerCase().includes(query.toLowerCase())) {
                                        return key;
                                    }
                                }).map(([key, val], index) => {
                                    return (
                                        !val.startsWith('custom') ?
                                            <Emoji type="button" key={index} onClick={() => setEmojiOnTextArea(val)} style={{ fontSize: 24 }}>
                                                {val}
                                            </Emoji>
                                            :
                                            ''
                                    );
                                }
                                )
                                }
                            </>
                        }
                    </Emojis>
                </EmojiButton>
            </div>
            <Ghost
                lineHeight={lineHeight ?? "var(--textarea-line-height)"}
                maxRows={maxRows ?? 5}>
                <div ref={ghost} style={{ padding }}>
                    {props.value
                        ? props.value
                            .split("\n")
                            .map((x) => `\u200e${x}`)
                            .join("\n")
                        : undefined ?? "‎\n"}
                </div>
            </Ghost>
        </Container >
    );
}
