import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import styled from 'styled-components';
import * as BsIcons from 'react-icons/bs';
import { RefObject } from 'preact';
import { useRef } from 'preact/hooks';
import { useEffect } from 'preact/hooks';

const Wrapper = styled.div`
    min-height: 400px;
    max-height: 400px;
    min-width: 560px;
    max-width: 560px;
    background-color: #090a0c;
`

interface Props {
    children: JSX.Element;
    open: Boolean;
    setOpen: Function;
    setQuery: Function;
}

export default function Emojis({ children, open, setOpen, setQuery }: Props) {
    const [referenceElement, setReferenceElement] = useState<any>(null);
    const [popperElement, setPopperElement] = useState<any>(null);
    const [tab, setTab] = useState('emoji');
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: "top-end",
        modifiers: [
            {
                name: "offset",
                options: {
                    offset: [0, 8]
                }
            }
        ]
    });

    return (
        <>
            <button type="button" onClick={() => setOpen(!open)} ref={setReferenceElement}>
                <BsIcons.BsEmojiSmileFill />
            </button>

            {open ? (
                <Wrapper<any> ref={setPopperElement} style={styles.popper} {...attributes.popper} className="border border-gray-700 rounded overflow-y-auto">
                    <div className="flex justify-center items-center px-3 pt-3 w-full">
                        <span className="relative z-0 inline-flex shadow-sm rounded-md">
                            {/* Active = bg-black, font-bold, text-white | not active = bg-zinc-900, font-light, text-gray-200 */}
                            <button
                                type="button"
                                className={`${tab == 'emoji' ? 'bg-black font-bold text-white' : 'bg-zinc-900 font-light text-gray-200'} relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-700 text-sm hover:bg-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500`}
                                onClick={() => setTab('emoji')}
                            >
                                Emojis
                            </button>
                            <button
                                type="button"
                                className={`${tab == 'GIF' ? 'bg-black font-bold text-white' : 'bg-zinc-900 font-light text-gray-200'} -ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-700 text-sm hover:bg-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500`}
                                onClick={() => setTab('GIF')}
                            >
                                GIFs
                            </button>
                        </span>
                    </div>
                    {tab == 'emoji' &&
                        <div className="emojis">
                            <div className="flex justify-center items center px-3 w-full">
                                <div className="flex w-full gap-1 mx-3 mt-3">
                                    <div className="w-full">
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            className="shadow-sm bg-black focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 px-3 py-2 rounded-full text-left"
                                            placeholder="Filter Emojis"
                                            // @ts-expect-error
                                            onChange={event => setQuery(event.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-8 p-3">
                                {children}
                            </div>
                        </div>
                    }
                    {tab == 'GIF' &&
                        <div className="gifs h-80">
                            <div className="flex flex-col h-full w-full justify-center items-center">
                                <img src="https://c.tenor.com/WZ_y7CzWuugAAAAC/everyone.gif" alt="GIFs coming soon!" className="h-48 w-48 rounded-full mb-5 object-cover" draggable={false} />
                                <h1 className="font-bold text-2xl">
                                    Coming soon!
                                </h1>
                            </div>
                        </div>
                    }
                </Wrapper>
            )
                : ''
            }
        </>
    );
};